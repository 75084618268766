.App {
  text-align: center;
}

html, body {
  margin: 0; height: 100%; overflow: hidden
}


.control-buttons {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 50dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.reset-button {
  position: absolute;
  z-index: 1000;
  width: 100vw;
  height: 95dvh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

button {
  background: linear-gradient(#21447d, #305ca2);
  border-radius: 1em;
  border: none;
  border-spacing: 0;
  color: #ffffff;
  font-family: system-ui;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.42rem;
  list-style: none outside none;
  padding-inline: 2em;
  padding-block: 0.8em;
  text-align: left;
  text-decoration: none;
  text-indent: 0;
  text-transform: uppercase;
  transition: all 0.5s ease-in-out;
}

.btn {
  border: none;
  outline: none;
  position: relative;
  z-index: 1;
  border-radius: 1em;
  background: linear-gradient(#3876d1, #ffffff);
  cursor: pointer;
}

.btn::before {
  content: "";
  position: absolute;
  left: 0.1em;
  right: 0.1em;
  top: 0.1em;
  bottom: 0.1em;
  border-radius: 1em;
  background: linear-gradient(#21447d, #305ca2);
  z-index: -1;
}

.container {
  position: relative;
  overflow: hidden;
}
